<template>
  <div>
    <!-- Add Button -->
    <div
      v-if="$can('store',resource)"
      class="text-right"
    >
      <b-button
        variant="primary"
        class="mb-1"
        @click="$bvModal.show(`add-${modalId}`)"
      >
        <span class="text-nowrap">Add {{ modalTitle }}</span>
      </b-button>
    </div>

    <!-- Search -->
    <b-card header="Search">
      <b-row>
        <b-col
          cols="12"
          class="mb-1"
        >
          <search
            placeholder="Name"
            :search-query.sync="searchQuery.name"
            @refresh="refreshTable"
          />
        </b-col>

        <slot
          name="additional-filters"
          :search-query="searchQuery"
        />

        <b-col md="6">
          <b-form-group
            label="Select Status"
            label-for="status-select"
          >
            <b-form-select
              id="status-select"
              v-model="searchQuery.status"
              placeholder="Select status"
              :options="[{text:'Both',value:null},{text:'Active',value:true},{text:'Inactive',value:false}]"
            />
          </b-form-group>
        </b-col>

        <b-col
          md="2"
          class="mt-2 ml-auto"
        >
          <search-button @refresh="refreshTable" />
        </b-col>
      </b-row>
    </b-card>

    <!-- Table -->
    <items-table
      ref="parentTable"
      v-bind="{
        getItems,
        pagination,
        modalId,
        toggleStatus:api.toggleStatus,
      }"
      :have-description="haveDescription"
      :have-category="haveCategory"
      :item.sync="item"
    />

    <!-- Pagination -->
    <pagination
      :total-rows="pagination.totalRows"
      :per-page="pagination.perPage"
      :current-page.sync="pagination.currentPage"
    />

    <div>
      <!-- Add Item -->
      <item-form
        :id="`add-${modalId}`"
        :title="`Add ${modalTitle}`"
        :item="item"
        :reset-item="api.resetItem"
        :submit="addItem"
      >
        <template #additional-inputs>
          <slot
            name="additional-inputs"
            :item="item"
          />
        </template>
      </item-form>
      <!-- Edit Item -->
      <item-form
        :id="`edit-${modalId}`"
        :title="`Edit ${modalTitle}`"
        :item="item"
        :reset-item="api.resetItem"
        :submit="updateItem"
      >
        <template #additional-inputs>
          <slot
            name="additional-inputs"
            :item="item"
          />
        </template>
      </item-form>
    </div>
  </div>
</template>

<script>
import SearchButton from '@/common/components/common/Table/SearchButton.vue'
import Search from '@/common/components/common/Table/Search.vue'
import Pagination from '@/common/components/common/Table/Pagination.vue'

import paginationData from '@/common/compositions/common/paginationData'
import ItemsTable from './DomainItemsTable.vue'
import ItemForm from './DomainItemForm.vue'

export default {
  name: 'DomainItemsList',
  components: {
    Pagination,
    Search,
    SearchButton,
    ItemsTable,
    ItemForm,
  },
  props: {
    modalId: { type: String, default: '' },
    modalTitle: { type: String, default: '' },
    type: { type: String, default: '' },
    api: { type: Object, default: () => {} },
    item: { type: Object, default: () => {} },
    haveDescription: { type: Boolean, default: true },
    haveCategory: { type: Boolean, default: true },
  },
  data() {
    return {
      searchQuery: { name: null, status: null, category: null },
    }
  },
  inject: ['resource'],
  setup() {
    const { pagination } = paginationData()
    return {
      pagination,
    }
  },
  methods: {
    getItems() {
      return this.api.getItemsRequest({ ...this.searchQuery, page: this.pagination.currentPage }).then(res => {
        this.pagination.totalRows = res.pagination.total
        return res.data
      })
    },
    addItem() {
      return this.api.addItemRequest().then(() => {
        this.refreshTable()
      })
    },
    updateItem() {
      this.$emit('update:item', { ...this.item })

      return this.api.updateItemRequest().then(() => {
        this.refreshTable()
      })
    },
    refreshTable() {
      this.$refs.parentTable.$refs.table.refresh()
    },
  },
}
</script>
