<template>
  <b-dropdown-item
    v-if="item.status"
    @click="toggleStatus(item)"
  >
    <feather-icon
      icon="ToggleRightIcon"
    />
    {{ deactivateText }}
  </b-dropdown-item>

  <b-dropdown-item
    v-else
    @click="toggleStatus(item)"
  >
    <feather-icon
      icon="ToggleLeftIcon"
    />
    {{ activateText }}
  </b-dropdown-item>
</template>
<script>

export default {
  name: 'ToggleStatus',
  props: {
    item: { type: Object, default: () => {} },
    toggleStatus: { type: Function, default: () => {} },
    activateText: { type: String, default: 'Activate' },
    deactivateText: { type: String, default: 'Deactivate' },
  },
}
</script>
<style lang="scss">

</style>
