<template>
  <b-card>
    <b-table
      ref="table"
      :items="getItems"
      :fields="tableColumns"
      :current-page="pagination.currentPage"
      :per-page="pagination.perPage"
      responsive
      show-empty
    >
      <!-- Column: Actions -->
      <template #cell(actions)="{item}">
        <b-dropdown
          variant="link"
          no-caret
        >
          <template #button-content>
            <feather-icon
              icon="MoreVerticalIcon"
              size="16"
              class="align-middle text-body"
            />
          </template>

          <template
            v-if="$can('update',resource)"
          >
            <toggle-status
              v-bind="{item,toggleStatus}"
            />
            <b-dropdown-item
              variant="outline-primary"
              @click="setItem(item);$bvModal.show(`edit-${modalId}`)"
            >
              <feather-icon icon="EditIcon" />
              <span> Edit </span>
            </b-dropdown-item>
          </template>
        </b-dropdown>
      </template>
    </b-table>
  </b-card>
</template>

<script>
import handleAlerts from '@/common/compositions/common/handleAlerts'
import ToggleStatus from '../../common/Table/ToggleStatus.vue'

export default {
  name: 'ItemsTable',
  components: { ToggleStatus },
  props: {
    modalId: { type: String, default: '' },
    getItems: { type: Function, default: () => [] },
    toggleStatus: { type: Function, default: () => [] },
    pagination: { type: Object, default: () => {} },
    haveDescription: { type: Boolean, default: true },
    haveCategory: { type: Boolean, default: true },
  },
  computed: {
    tableColumns() {
      return [
        { key: 'name' },
        ...(this.haveDescription ? [{ key: 'description' }] : []),
        ...(this.haveCategory ? [{ key: 'category' }] : []),
        { key: 'status', formatter: v => (v ? 'Active' : 'Inactive') },
        { key: 'actions' },
      ]
    },
  },
  inject: ['resource'],
  setup() {
    const { successfulOperationAlert } = handleAlerts()
    return { successfulOperationAlert }
  },
  methods: {
    setItem(item) {
      this.$emit('update:item', { ...item })
    },
  },
}
</script>
