<template>
  <validation-observer
    v-slot="{ handleSubmit }"
  >
    <b-form
      class="p-2"
      @submit.enter.prevent
    >
      <b-modal
        :id="id"
        ref="domain_item_modal"
        centered
        :title="title"
        no-close-on-backdrop
        :size="size"
        @hidden="resetItem()"
      >
        <!-- Title -->
        <text-input
          id="title"
          name="Title"
          rules="required"
          :text.sync="item.name"
        />
        <slot
          name="additional-inputs"
        />
        <!-- Status -->
        <b-form-group
          v-if="isStatusEnabled"
          label="Status"
          label-for="status"
        >
          <b-checkbox
            id="status"
            v-model="item.status"
            switch
          />
        </b-form-group>
        <!-- Form Actions -->
        <template #modal-footer="{cancel}">
          <b-button
            @click="cancel()"
          >
            Cancel
          </b-button>

          <submit-button
            :handle-submit="handleSubmit"
            :submit="submitRequest"
            submit-text="Save"
          />
        </template>

      </b-modal>
    </b-form>
  </validation-observer>
</template>

<script>
import SubmitButton from '@/common/components/common/FormInputs/SubmitButton.vue'
import TextInput from '../../common/FormInputs/TextInput.vue'

export default {
  name: 'ItemForm',
  components: {
    SubmitButton,
    TextInput,
  },
  props: {
    id: { type: String, default: '' },
    title: { type: String, default: '' },
    size: { type: String, default: 'lg' },
    resetItem: { type: Function, default: () => {} },
    item: { type: Object, default: () => {} },
    submit: { type: Function, default: () => {} },
    isStatusEnabled: { type: Boolean, default: true },
    isCategoryEnabled: { type: Boolean, default: true },
  },
  methods: {
    submitRequest() {
      return this.submit().then(() => { this.$refs.domain_item_modal.hide() })
    },
  },
}
</script>

<style>

</style>
