import { computed, ref } from '@vue/composition-api'
import axiosInstances from '@/libs/axios-instances'
import handleAlerts from '../common/handleAlerts'

const { successfulOperationAlert } = handleAlerts()
export default function DomainItem(apiService, apiURL) {
  const initialItem = computed(() => ({
    islamicOrganization: { status: true, category: 'islamic organization' },
    business: { status: true, category: 'business' },
  }))

  const item = ref({ ...initialItem.value.business })

  const getItemsRequest = params => axiosInstances[apiService].get(apiURL, { params }).then(res => res.data).catch(() => [])

  const addItemRequest = () => axiosInstances[apiService].post(apiURL,
    {
      ...item.value,
      tag_name: item.value.name,
      scope_type: 'master',
    }).then(() => {
    successfulOperationAlert('Item is added successfully')
  })

  const updateItemRequest = () => axiosInstances[apiService].put(`${apiURL}/${item.value.id}`, item.value).then(() => {
    successfulOperationAlert('Item is updated successfully')
  })

  const toggleStatus = selectedItem => axiosInstances[apiService].put(`${apiURL}/${selectedItem.id}/status`).then(() => {
    const operationType = selectedItem.status ? 'Deactivated' : 'Activated'
    selectedItem.status = !selectedItem.status
    successfulOperationAlert(`Item is ${operationType} successfully`)
  })

  const resetItem = (category = 'business') => {
    item.value = { ...initialItem.value[category] }
  }
  return {
    item, initialItem, getItemsRequest, addItemRequest, updateItemRequest, resetItem, toggleStatus,
  }
}
